.dialogContent {
  padding: 10px 38px 20px 38px;
  min-height: 300px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.thumbs {
  width: 60px;
  height: 60px;
}
.txt {
  padding: 0;
  margin: 0;
  margin-top: 21px;
  color: #65b6db;
  font-weight: 900;
  font-style: italic;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  cursor: default;
}

@media only screen and (max-width: 500px) {
  .txt {
    font-size: 22px;
  }
  .dialogContent {
    min-width: 200px;
    padding: 10px 10px 10px 0;
  }
}
