.headerContainer {
  padding-top: 30px;
  text-align: center;
}

.headerText {
  margin: 15px;
  /* font-family: Avenir; */
  font-size: 28px;
  font-weight: 900;
  font-style: oblique;
  color: #e36363;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
}

.confirmOnlyButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin: 30px 40px;
}

.inputLabel {
  min-height: 22px;
  /* font-family: Avenir; */
  font-size: 16px;
  font-weight: 900;
  color: var(--black);
  margin-top: 20px;
  margin-left: 8px;
}
.exclamation {
  height: 24px;
  margin-left: 10px;
  margin-right: 6px;
  margin-bottom: -2px;
}
.warningPrimaryText {
  color: #e46363;
  font-size: 16px;
  margin-bottom: -2px;
}
.warningSecondaryText {
  color: #e46363;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
}
.whiteCloseButton {
  filter: brightness(2);
}
