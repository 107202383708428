.wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: 0 20px;
}

.footer {
  margin-top: 0px;
}
.footerSubmit {
  margin-top: 10px;
}
.navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28%;
}

.hr {
  color: lightgrey;
  background-color: lightgrey;
  height: 2px;
  border: none;
}
@media only screen and (max-width: 400px) {
  .footerSubmit {
    margin-top: 0px;
  }
}
