.container {
  display: flex;
  position: relative;
  height: 500px;
  justify-content: center;
  margin-bottom: 30px;
}

.numberCell {
  position: relative;
  height: 11%;
  top: -52px;
}

.circle {
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-align: center;
  display: flex;
}

.selected {
  color: white;
}

.numbers {
  position: relative;
  top: -14px;
  left: -4px;
  width: 30px;
  height: 100%;
}

.face {
  position:relative;
  top: -12px;
  right: -10px;
  height: 100%;
  width: auto;
}

.nine {
  right: -15px;
}

.img {
  max-width: 100%;
  max-height: 100%;
}

.faceIcons {
  position: relative;
  width: 80px;
  height: 100%;
}

.faceContainer {
  position: relative;
  width: 100%;
  top: -77px;
  height: 11%;
}

.line {
  height: 10px;
  width: 100%;
  transform: translate(0, 210%);
}

.blue {
  background-color: #2894fe;
}

.lightblue {
  background-color: #3cbdf2;
}

.teal {
  background-color: #61d9c6
}

.green {
  background-color: #9be185
}

.lime {
  background-color: #b9e77b
}

.yellow {
  background-color: #f1e981
}

.khaki {
  background-color: #fae282
}

.sand {
  background-color: #fdcd81
}

.orange {
  background-color: #feb180
}

.red {
  background-color: #fc9581
}
