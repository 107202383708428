.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  z-index: 100;
  background-color: white;
}

.img {
  position: relative;
  top: 20%;
  width: 80%;
  display:block;
  margin:auto;
}

.text {
  position: relative;
  top: 40%;
  width: 80%;
  text-align: center;
  display:block;
  margin:auto;
}