.header {
  margin-top: 10px;
  width: 100%;
  position: relative;
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.crumb {
  flex-grow: 1;
  background-color: lightgrey;
  border-radius: 2px;
  margin: 0 3px;
  height: 6px;
  position: relative;
}

.selected {
  background-color: #2591FE;
}

.questionsLeft {
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: grey;
}

.questionsLeft {
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: grey;
}

.timeLeft {
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: red;
  width: 130px;
}

.flex {
  display: flex;
  justify-content: space-between;
}