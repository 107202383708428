.mainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 50px;
  font-style: italic;
  font-family: fantasy;
  text-align: center;
}
