.item {
  height: 85px;
  padding: 8px;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: white;
  list-style: none;
  margin: 4px 0;
}

.items {
  list-style-type: none;
  padding: 4px;
}

.container {
  display: flex;
  align-items: center;
}

.number {
  height: 85px;
  margin: 4px 0;
  padding: 8px;
  color: #2591fe;
  font-weight: bold;
  font-size: 1.5em;
}

.numbers {
  padding: 4px;
}
