.text {
  font-weight: bold;
  color: darkslategray;
}

.helpText {
  color: grey;
  font-style: italic;
}

.container {
  position: relative;
  margin-bottom: 20px;
}