.logo {
  display: block;
  height: 100px;
  margin: auto;
  position: relative;
  padding-top: 1%;
  max-width: 350px;
  width: 100%;
  max-height: 60px;
  object-fit: contain;
  margin-bottom: 5px;
}
.popover {
  padding: 20px;
  color: #7746e1;
}

.continueButton {
  margin-top: 20px;
  width: 100%;
  /* outline: 1px solid red; */
  text-align: center;
}
.heading {
  color: #7746e1;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  margin: 0;
  text-align: center;
}
.description {
  color: #7746e1;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 1.5px;
  margin: 8px 0 0 0;
  text-align: center;
  max-width: 300px;
  margin: auto;
}
.blink {
  animation-name: blink;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 500px) {
  .heading {
    font-size: 32px;
  }
  .description {
    margin-top: 4px;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 21px;
  }
}
