.container {
  background: url(../../images/graduation.jpg) no-repeat center center fixed;
  position: fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
  z-index: 100;
  left: 0;
}

.img {
  position: absolute;
  top: 10%;
  width: 100%;
}