.dialogContent {
  padding: 20px 38px 30px 38px;
}
.description {
  padding-bottom: 20px;
  border: 1px solid #9bcc64;
  border-radius: 5px;
  padding: 15px;
  font-style: italic;
  color: #45770d;
  font-size: 18px;
}
.subHeading {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.errorMessage {
  color: red;
  margin: 5px 0;
}
.timerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  font-size: 17px;
  color: #191919;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.resend {
  text-decoration: underline;
  cursor: pointer;
}
.disabled {
  opacity: 0.7;
}
.resend:hover {
  color: rgb(23, 23, 192);
}
.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
