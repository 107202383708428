.container {
  background-color: #2591fe;
  z-index: 100;
  overflow: none;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.completed {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  color: white;
  font-size: 2em;
  padding: 0 25px;
  text-align: center;
}

.icon {
  width: 7em !important;
  height: 7em !important;
}
