.welcome {
  background: url(../../images/compressed_smile.jpg) no-repeat center center
    fixed;
  position: relative;
  background-size: cover;
  /*height: 100vh;  */
  min-height: 100vh;
}

.overlay {
  background: linear-gradient(
    rgba(132, 232, 255, 0.9),
    rgba(37, 145, 254, 0.9)
  );
  padding-bottom: 10px;
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;*/
  min-height: 100vh;
}

.dob {
  display: flex;
}

.firstName {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
}

.lastName {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
}

.selfOrChild {
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.continueButton {
  margin-top: 20px;
  width: 100%;
  /* outline: 1px solid red; */
  text-align: center;
}

.dobText {
  color: white;
  display: flex;
  align-items: center;
  width: 460px;
  vertical-align: bottom;
}

.logo {
  display: block;
  height: 100px;
  margin: auto;
  position: relative;
  padding-top: 30px;
  max-width: 350px;
  width: 100%;
  max-height: 60px;
  object-fit: contain;
  margin-bottom: 5px;
}

.inputs {
  margin: 10px 0;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
  /* margin-bottom: 10%; */
  margin-bottom: 25px;
}

.go {
  position: relative;
  border-bottom: 1px solid white;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  display: inline-block;
}

.popover {
  padding: 20px;
}

.linkText {
  height: 26px;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.sorryDiv {
  background: url(../../images/compressed_smile.jpg) no-repeat center center
    fixed;
  text-align: center;
  position: relative;
  background-size: cover;
  height: 100vh;
  min-height: 100vh;
}
.sorry {
  background: linear-gradient(
    rgba(132, 232, 255, 0.9),
    rgba(37, 145, 254, 0.9)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10%;
  color: white;
  font-weight: 600;
  font-style: italic;
  font-size: 50px;
}

@media only screen and (max-width: 600px) {
  .sorry {
    font-size: 30px;
  }
}
